import React, { useState } from "react";
import Header from "./Header";
import { styled } from "@mui/system";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";
import "./line.css";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { Container, Grid, CircularProgress } from "@mui/material";
import BusStopStepper from "./BusStopContainer";
import MapComponent from "./MapComponentB";
import buslogo from "../assets/Bus.svg";
import charging from "../assets/Charging.svg";
import Routes from "../assets/Routes.svg";
import tripicon from "../assets/Trip.svg";
import opretionHouresIcon from "../assets/opehours.svg";
import TcharginhrIcon from "../assets/Tcharginhr.svg";
import TkilomitterIcon from "../assets/Tkilomitter.svg";
import "../App.css";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "18px",
      marginTop: "0",
      height: "44px",
      width: "250px",
      color: "#ADADAD",
    },
  },
});

const MainContainer = styled("div")({
  width: "100%",
  height: "auto",
  background: "linear-gradient(#626262 0%, #484848 50%, #323232 100%)",
  margin: "0 auto",
  position: "relative",
  border: "2px solid #000",
  borderRadius: "1px",
  "@media (max-width: 768px)": {
    width: "90%",
  },
  "@media (max-width: 600px)": {
    width: "100%",
  },
});
const SubContainer = styled("div")({
  width: "100%",
  height: "auto",
  background: "linear-gradient(#626262 0%, #484848 50%, #323232 100%)",
  margin: "1 auto",
  position: "relative",
  border: "2px solid #000",
  borderRadius: "1px",
  "@media (max-width: 768px)": {
    width: "90%",
  },
  "@media (max-width: 600px)": {
    width: "100%",
  },
});
const Rectangle = styled("div")({
  width: "100%",
  height: "auto",
  background: "linear-gradient(#626262 0%, #484848 50%, #323232 100%)",
  position: "absolute",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px",
});
const Rectangle1 = styled("div")({
  width: "100%",
  height: "auto",
  background: "linear-gradient(#626262 0%, #484848 50%, #323232 100%)",
  position: "absolute",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px",
});
const StyledSelect = styled(Select)({
  width: "16%",
  height: "80%",
  marginLeft: 7,
  minHeight: "30%",
});

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: "18px",
    marginTop: "0",
    height: "44px",
    width: "100%",
    color: "#ADADAD",
  },
});

const ResponsiveButton = styled(Button)({
  borderRadius: "24px",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

const Dashboard = () => {
  const [center, setCenter] = useState([73, 18]);
  const [zoom, setZoom] = useState(12);

  const [date, setDate] = useState("");
  const [busNumber, setBusNumber] = useState("");
  const [routeName, setRouteName] = useState("");
  const [statistics, setStatistics] = useState(null);
  const classes = useStyles();
  const handleSearch = () => {
    // Construct the URL for your API endpoint with query parameters
    const apiUrl = `https://your-api-url/search?date=${date}&busNumber=${busNumber}&routeName=${routeName}`;

    // Make a GET request to the API endpoint
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Update state with the received statistics
        setStatistics(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div className="App">
      <SubContainer>
        <Header />

        <Box sx={{ display: "flex", backgroundColor: "#4D4D4D", marginTop: 1 }}>
          <Grid
            container
            spacing={1}
            style={{
              width: "100%",
              height: "100%",
              marginTop: "1%",
              marginLeft: "1%",
              marginBottom: "1%",
              marginRight: "1%",
            }}
          >
            {/* Map Container */}
            <Grid
              item
              xs={12}
              md={8.1}
              style={{
                width: "100%",
                height: "100%",
                marginRight: "1%",
                border: "1px solid #808080",
              }}
            >
              <MapComponent />
            </Grid>

            <Grid
              item
              xs={12}
              md={3.5}
              style={{
                width: "100%",
                height: "91vh",
                border: "1px solid #808080",
              }}
            >
              <BusStopStepper />
            </Grid>
          </Grid>
        </Box>
      </SubContainer>
    </div>
  );
};

export default Dashboard;
