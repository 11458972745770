import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  styled,
} from "@mui/material";
// import { BusStopOutlined as BusStopIcon } from '@mui/icons-material';

// Custom styling for the StepConnector to create a round shape line
const CustomStepConnector = styled(StepConnector)({
  "& .MuiStepConnector-line": {
    borderTop: "2px solid #eaeaf0",
    borderRadius: "1px",
  },
});

const BusStopIcon = styled("div")(({ theme, active }) => ({
  width: 24,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: "black",
}));

// Custom StepIcon component to render bus stop icon
const CustomStepIcon = styled("div")(({ theme, active }) => ({
  width: 24,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: active ? "yellow" : "black",
}));

function BusStopStepper({ currentStop, totalStops }) {
  const [ggdata, setGgdata] = useState([]);

  useEffect(() => {
    // fetch("/data/locations2.json")
    fetch("https://timscan.transvolt.in/vehical/Get-Device-Details/?name=MBMT")
      .then((response) => response.json())
      .then((data) => {
        setGgdata(data);
        // const a = data.map((a) =>
        //   a.device_location.type ? a.device_location : null
        // );
        // const b = { type: "FeatureCollection", features: a.filter(Boolean) };
      });
  }, []);

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-secondary">
            <div className="card-header">
              <h6 className="card-title">Buses</h6>
            </div>
            <div
              className="card-body table-responsive p-0"
              style={{ height: "78vh"  ,fontSize:11}}
            >
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    {/* <th style={{ width: "10px" }}>#</th> */}
                    <th style={{ width: 10 }}>Zone</th>
                    <th>Device Name</th>
                    <th>Bus Number</th>
                    <th>State Of Charge</th>
                    <th style={{ width: 40 }}>Pilot Name</th>
                  </tr>
                </thead>
                <tbody>
                  {ggdata.map((dn, index) =>
                    dn.device_details.name == "MBMT-26" ||
                    dn.device_details.name == "MBMT-25" ? (
                      <tr>
                        {/* <td>{index + 1}</td> */}
                        <td>
                          145 <br />
                          MiraBhaindar
                        </td>
                        <td>{dn.device_details.name}</td>
                        <td>{dn.device_details.registrationNumber}</td>
                        <td>
                          <div className="progress progress-xs">
                            <div
                              className="progress-bar progress-bar-danger"
                              style={{
                                width: dn.device_location.properties
                                  ? dn.device_location.properties.stateOfCharge
                                  : "0" + `%`,
                              }}
                            ></div>
                          </div>
                          <span className="badge bg-danger">
                            {dn.device_location.properties
                              ? dn.device_location.properties.stateOfCharge
                              : ""}
                          </span>
                        </td>
                        <td>
                          {dn.device_details.name == "MBMT-26"
                            ? "Umesh #2307"
                            : "Suraj #2301"}
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* 
      <Typography variant="h6" gutterBottom style={{ color: '#fff', backgroundColor: '#000' }}>
        
      </Typography>
       */}
      </div>
    </div>
  );
}

export default BusStopStepper;
