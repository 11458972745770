import React, { useState, useEffect, useRef } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import "ol/ol.css";
import XYZ from "ol/source/XYZ";
import { fromLonLat } from "ol/proj";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Style, Icon } from "ol/style";
import Overlay from "ol/Overlay";
import busicon from "../assets/Bus-Yellow.svg";

function MapComponentB() {
  const overlayRef = useRef();
  //   const [ggdata, setGgdata] = useState([]);

  useEffect(() => {
    const osmLayer = new TileLayer({
      source: new XYZ({
        url: "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
      }),
      zIndex: 0,
      opacity: 2,
    });
    // const osmLayer = new TileLayer({
    //     preload: Infinity,
    //     source: new OSM(),
    // })

    const map = new Map({
      target: "map",
      layers: [osmLayer],
      view: new View({
        center: fromLonLat([72.888750277138726119, 19.284400328213461506]),
        zoom: 14,
      }),

      //   view: new View({
      //     center: [19, 72],
      //     zoom: 1,
      //   }),
    });

    // Create overlay for popup
    const overlay = new Overlay({
      element: overlayRef.current,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    map.addOverlay(overlay);

    // Fetch and display geojson data
    // fetch("/data/locations2.json")
    const fetchData = () => {
      fetch("https://timscan.transvolt.in/vehical/Get-Device-Details/?name=MBMT")
        .then((response) => response.json())
        .then((data) => {
          const a = data.map((a) =>
            (a.device_details.name == "MBMT-26" ||
              a.device_details.name == "MBMT-25") &&
            a.device_location.type
              ? a.device_location
              : null
          );
          const b = { type: "FeatureCollection", features: a.filter(Boolean) };

          const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(b, {
              featureProjection: "EPSG:3857",
            }),
          });

          const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: new Style({
              image: new Icon({
                // src: "https://img.icons8.com/external-smashingstocks-glyph-smashing-stocks/66/FA5252/external-bus-transport-smashingstocks-glyph-smashing-stocks.png",
                src: busicon,
                scale:2,
                // src: "https://openlayers.org/en/v6.5.0/examples/data/icon.png",
              }),
            }),
          });

          map.addLayer(vectorLayer);

          // Add click event to display popup
          map.on("singleclick", function (evt) {
            const feature = map.forEachFeatureAtPixel(
              evt.pixel,
              function (feature) {
                return feature;
              }
            );

            if (feature) {
              const coordinates = feature.getGeometry().getCoordinates();
              overlay.setPosition(coordinates);
              const content = `
              <table className="table table-bordered" border="1" style="font-size: 11px; font-family: Arial, sans-serif;">
                <tr>
                  <td>Name</td>
                  <td>${feature.get("device_name")}</td>
                </tr>
                <tr>
                  <td>Bus Number</td>
                  <td>${feature.get("registrationNumber")}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>${feature.get("address")}</td>
                  </tr>
                  <table>`;
              document.getElementById("popup-content").innerHTML = content;
            } else {
              overlay.setPosition(undefined);
            }
          });
        });
    };

    fetchData();

    // const intervalId = setInterval(fetchData, 60000); // Refresh every minute

    return () => {
      // clearInterval(intervalId);
      map.setTarget(null);
    };
  }, []);

  return (
    <>
      <div
        style={{ height: "90vh", width: "100%" }}
        id="map"
        className="map-container"
      />
      <div ref={overlayRef} id="popup" className="ol-popup">
        <div id="popup-content"></div>
      </div>
    </>
  );
}

export default MapComponentB;
