// Header.js

import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/system";
import logo from "../assets/MBMT.jpg";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "black",
  height: "80px",
  // Set a default width\
  width: "100%",
  margin: 0,
  // Use media queries to adjust width for different screen sizes
  "@media (min-width: 600px)": {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  "@media (min-width: 960px)": {
    width: "100%",
  },
  "@media (min-width: 1280px)": {
    width: "100%",
  },
});

const LogoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between", // Align items to the start and end of the container
  width: "100%",
  margin:8
});

const UserContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const UserAvatar = styled(Avatar)({
  borderRadius: "40%",
  marginLeft: "8px", // Add some space between username and avatar
});

const Header = ({ username }) => {
  return (
    <StyledAppBar position="static">
      <Toolbar>
        <LogoContainer>
          {/* Left side logo */}
          <img
            src={logo}
            alt="Logo"
            style={{ maxWidth: "100%", height: 80, marginTop: -2 }}
          />
        
         
        </LogoContainer>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
